import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@mui/material";
import { GetAcitivityChartData } from "../../../api";
import moment from "moment";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const ActivitiesChart = ({ setFilterOptions }) => {
  const [chartOptions, setChartOptions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize the date range (last 3 days from today)
  const [dateRange, setDateRange] = useState({
    start: moment().subtract(3, "days").format("MM/DD/YYYY"),
    end: moment().subtract(1, "days").format("MM/DD/YYYY"),
  });

  const [value, setValue] = useState(0);
  const step = 4;

  const handleChange = (val) => {
    setValue(Math.round(val / step) * step);
  };

  const fetchChartData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Prepare payload for API
      const payload = {
        date_range: `${dateRange.start} - ${dateRange.end}`,
      };

      // Fetch data from API
      const result = await GetAcitivityChartData(payload);

      if (result.statusCode === 200 && result.status === "success") {
        const { categories, chartdata } = result.data;

        // Check for empty data
        if (
          !categories ||
          categories.length === 0 ||
          !chartdata ||
          chartdata.length === 0
        ) {
          setChartOptions({
            chart: {
              type: "line",
              backgroundColor: "#0b0c10",
              events: {
                load: function () {
                  this.renderer
                    .text(
                      "No Data Available for Selected Range",
                      this.chartWidth / 2,
                      this.chartHeight / 2 - 20
                    )
                    .css({
                      color: "#ffffff",
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "center",
                    })
                    .attr({
                      align: "center",
                    })
                    .add();

                  this.renderer
                    .text(
                      "Please select a different date range",
                      this.chartWidth / 2,
                      this.chartHeight / 2 + 10
                    )
                    .css({
                      color: "#cccccc",
                      fontSize: "12px",
                      textAlign: "center",
                    })
                    .attr({
                      align: "center",
                    })
                    .add();
                },
              },
            },
            title: {
              text: "",
            },
            xAxis: {
              visible: false,
            },
            yAxis: {
              visible: false,
            },
            series: [],
          });
          return;
        }

        // Prepare categories and plotBands
        const xAxisCategories = [];
        const plotBands = [];
        let currentStart = 0;

        categories.forEach((category, index) => {
          category.categories.forEach((hour) => {
            xAxisCategories.push(`${hour}`); // Push hours for X-axis labels
          });

          // Add plotBands for date sections
          plotBands.push({
            color:
              index % 2 === 0 ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0)", // Alternate shading
            from: currentStart,
            to: currentStart + category.categories.length,
            label: {
              text: category.name,
              align: "center",
              style: {
                color: "#ffffff",
                fontSize: "10px",
              },
              y: 20, // Adjust Y-position of the date label
            },
          });

          currentStart += category.categories.length; // Update start index for next section
        });

        // Update chart options
        setChartOptions({
          chart: {
            type: "line",
            backgroundColor: "#0b0c10",
          },
          title: {
            text: "Activity Over Time",
            style: {
              color: "#ffffff",
            },
          },
          xAxis: {
            categories: xAxisCategories,
            plotBands: plotBands,
            labels: {
              style: {
                color: "#ffffff",
                fontStyle: "normal",
                fontSize: "12px",
              },
            },
          },
          yAxis: {
            title: {
              text: "Count",
              style: {
                color: "#ffffff",
              },
            },
            labels: {
              style: {
                color: "#ffffff",
              },
            },
          },
          series: [
            {
              name: "Activity",
              data: chartdata,
              color: "#00ff00", // Line color
            },
          ],
          legend: {
            itemStyle: {
              color: "#ffffff",
            },
          },
        });
      } else {
        throw new Error(result.message || "Failed to fetch data.");
      }
    } catch (err) {
      setError(err.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [dateRange]);

  // Handlers for shifting date range
  const handleShiftLeft = () => {
    setDateRange((prev) => ({
      start: moment(prev.start, "MM/DD/YYYY")
        .subtract(3, "days")
        .format("MM/DD/YYYY"),
      end: moment(prev.end, "MM/DD/YYYY")
        .subtract(3, "days")
        .format("MM/DD/YYYY"),
    }));
  };

  const handleShiftRight = () => {
    setDateRange((prev) => ({
      start: moment(prev.start, "MM/DD/YYYY")
        .add(3, "days")
        .format("MM/DD/YYYY"),
      end: moment(prev.end, "MM/DD/YYYY").add(3, "days").format("MM/DD/YYYY"),
    }));
  };

  // Show loader or error message
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ color: "red", textAlign: "center", marginTop: "20px" }}>
        Error: {error}
      </div>
    );
  }

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div style={{ position: "absolute", top: 10, right: 10, zIndex: 10 }}>
        <button
          onClick={handleShiftLeft}
          style={{
            backgroundColor: "#0b0c10",
            color: "#ffffff",
            border: "1px solid #ffffff",
            padding: "5px 10px",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          ←
        </button>
        <button
          onClick={handleShiftRight}
          style={{
            backgroundColor: "#0b0c10",
            color: "#ffffff",
            border: "1px solid #ffffff",
            padding: "5px 10px",
            cursor: "pointer",
          }}
        >
          →
        </button>
      </div>
      <div
        style={{
          width: "92.5%",
          position: "absolute",
          bottom: -25,
          left: 70,
          zIndex: 99999,
        }}
      >
        <Slider
          min={0}
          max={68} // 72 - 4
          step={step}
          value={value}
          onChange={handleChange}
          styles={{
            rail: {
              backgroundColor: "#f0f0f0",
              height: 24,
              borderRadius: 4,
            },
            track: {
              display: "none", // Hide default track
            },
            handle: {
              width: `${5.56}%`,
              height: "24px",
              backgroundColor: "#4CAF50",
              border: "none",
              borderRadius: "6px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
              marginTop: "0px",
              zIndex: 2,
              cursor: "pointer",
              transition: "left 0.2s ease",
            },
          }}
        />
      </div>
      {/* <HighchartsReact highcharts={Highcharts} options={chartOptions} /> */}
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        style={{
          height: "100%", // Ensure Highcharts fits the container
          width: "100%", // Make sure chart occupies full width
        }}
      />
    </div>
  );
};

export default ActivitiesChart;
