import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Card,
  List,
  ListItem,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
} from "@mui/material";
import { CameraAlt, FilterAlt as FilterAltIcon } from "@mui/icons-material";
import styled from "styled-components/macro";
import FilterActivityDialog from "./FilterActivityDialog";
import InfiniteScroll from "react-infinite-scroll-component";
import { GetActiveInactiveUsers } from "../../../api";
import DetailedLog from "../../reports/inner-components/DetailedLog";
import NTTImageDialog from "./NTTImageDialog";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "140px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const UserActivityList = ({
  liveDispatchData,
  loading,
  setActivityPayload,
  fetchMoreActivities,
  hasMore,
  setMapCenter,
  setLocationIds,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activitiesList, setActivitiesList] = useState([]);
  const [userType, setUserType] = useState(null);
  const [userList, setUserList] = useState([]);
  const [lastUpdateTime, setLastUpdateTime] = useState("00:00:00");

  //State for NTT Image Dialog
  const [nttImageDialog, setNttImageDialog] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const nttImageDialogOpen = (activity) => {
    setSelectedActivity(activity);
    setNttImageDialog(true);
  };

  const nttImageDialogClose = () => {
    setSelectedActivity(null);
    setNttImageDialog(false);
  };

  // State for filter activity dialog box
  const [filterActivityDialog, setFilterActivityDialog] = useState(false);

  // Detailed log modal
  const [userIdDialog, setUserIdDialog] = useState(null);
  const [modaldetailedlog, setModalDetailedLog] = useState(false);
  const modalDetailedLogOpen = () => {
    setModalDetailedLog(true);
  };
  const modalDetailedLogClose = () => {
    setModalDetailedLog(false);
  };

  const updateMapCenter = (data) => {
    setMapCenter({
      lat: data?.lattitude,
      lng: data?.longitude,
    });
  };

  const getUsers = async (payload) => {
    try {
      setIsLoading(true);
      setUserList([]);
      const res = await GetActiveInactiveUsers(payload);
      setUserList(res?.data);
    } catch (error) {
      console.log("Getting Errors while fetching users --", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (liveDispatchData?.map_data) {
      setActivitiesList(liveDispatchData?.map_data);
    }
  }, [liveDispatchData]);

  useEffect(() => {
    if (activitiesList.length > 0) {
      const latestActivityTime = new Date(activitiesList[0].activityTime); // Parse the activity time

      const updateTimer = () => {
        const currentTime = new Date();
        const diffInSeconds = Math.floor(
          (currentTime - latestActivityTime) / 1000
        );

        // Calculate hours, minutes, and seconds
        const hours = Math.floor(diffInSeconds / 3600)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((diffInSeconds % 3600) / 60)
          .toString()
          .padStart(2, "0");
        const seconds = (diffInSeconds % 60).toString().padStart(2, "0");

        setLastUpdateTime(`${hours}:${minutes}:${seconds}`);
      };

      // Update the timer immediately
      updateTimer();

      // Start a timer to update every second
      const intervalId = setInterval(updateTimer, 1000);

      // Cleanup on component unmount
      return () => clearInterval(intervalId);
    }
  }, [activitiesList]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="outlined"
          color="success"
          aria-label="Active Users"
          aria-owns={anchorEl ? "icon-menu" : undefined}
          aria-haspopup="true"
          onMouseOver={(e) => {
            setAnchorEl(e.currentTarget);
            setUserType("active");
            getUsers({ status: "Y" });
          }}
        >
          {liveDispatchData?.activeUser || 0}
        </Button>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            marginBottom: "0px",
          }}
        >
          Last Update: {lastUpdateTime}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <IconButton
            onClick={() => {
              setFilterActivityDialog(true);
            }}
          >
            <FilterAltIcon />
          </IconButton>
          <Button
            aria-label="Inactive Users"
            aria-owns={anchorEl ? "icon-menu" : undefined}
            aria-haspopup="true"
            onMouseOver={(e) => {
              setAnchorEl(e.currentTarget);
              setUserType("inactive");
              getUsers({ status: "N" });
            }}
            style={{ color: "#1374C5" }}
            color="error"
            variant="outlined"
          >
            {liveDispatchData?.inactiveUser || 0}
          </Button>
          <StyledMenu
            id="icon-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem disabled>
              <Typography variant="body2" gutterBottom>
                {userType === "active" ? "Active Users" : "Inactive Users"}
              </Typography>
            </MenuItem>

            {isLoading ? (
              <MenuItem>
                <Typography variant="body2" gutterBottom>
                  Loading...
                </Typography>
              </MenuItem>
            ) : (
              userList.map((user) => {
                return (
                  <MenuItem
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setUserIdDialog(user?.user_id);
                      modalDetailedLogOpen();
                    }}
                  >
                    <Typography variant="body2" gutterBottom>
                      {user?.user_name}
                    </Typography>
                  </MenuItem>
                );
              })
            )}
          </StyledMenu>
        </Box>
      </Box>
      <Card
        id="scrollableDiv"
        variant="outlined"
        sx={{
          marginTop: "10px",
          boxSizing: "border-box",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <CircularProgress size={24} />
          </Box>
        ) : activitiesList.length === 0 ? (
          <Box sx={{ padding: "20px" }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              Mobile users not appearing here? Try these steps:
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "left", marginBottom: "15px" }}
            >
              1. Log them out <br />
              2. Confirm data connection <br />
              3. Log them in
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <Button variant="outlined">Other troubleshooting tips</Button>
            </Box>
          </Box>
        ) : (
          <InfiniteScroll
            dataLength={activitiesList.length}
            next={fetchMoreActivities}
            hasMore={hasMore}
            loader={
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <CircularProgress size={24} />
              </Box>
            }
            endMessage={
              <Typography
                sx={{
                  textAlign: "center",
                  padding: "10px",
                  color: "gray",
                  fontSize: "14px",
                }}
              >
                No more activities to show
              </Typography>
            }
            style={{
              height: "100%",
              overflow: "auto",
            }}
            scrollableTarget="scrollableDiv"
          >
            {activitiesList.map((activity, index) => (
              <ListItem
                key={index}
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={10} md={10} lg={10}>
                    <Typography variant="body2" gutterBottom>
                      <span
                        style={{
                          color: "blue",
                          marginRight: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setUserIdDialog(activity?.userId);
                          modalDetailedLogOpen();
                        }}
                      >
                        {activity.user_name}
                      </span>
                      {activity.activity_type}:
                      <span style={{ marginLeft: "4px" }}>
                        {activity.buttonName}
                      </span>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {activity.LocationName || "No Location Name"}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {activity.scanTime || "No Scan Time"}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} md={2} lg={2}>
                    <img
                      onClick={() => updateMapCenter(activity)}
                      src={
                        activity?.profile_image ||
                        "../../../../public/static/img/avatars/profile-upload.png"
                      }
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        objectFit: "cover", // Ensures the image fits nicely
                      }}
                    />
                    {activity?.images &&
                      activity?.images.length > 0 &&
                      (activity?.activity_type === "Non-Tour Task Started" ||
                        activity?.activity_type ===
                          "Non-Tour Task Completed") && (
                        <IconButton
                          onClick={() => {
                            nttImageDialogOpen(activity);
                          }}
                        >
                          <CameraAlt />
                        </IconButton>
                      )}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </InfiniteScroll>
        )}
      </Card>
      {/* Filter Activity Dialog */}
      {filterActivityDialog && (
        <FilterActivityDialog
          filterActivityDialog={filterActivityDialog}
          setFilterActivityDialog={setFilterActivityDialog}
          setActivityPayload={setActivityPayload}
          setLocationIds={setLocationIds}
        />
      )}
      {/* User Activity Dialog */}
      {modaldetailedlog && (
        <DetailedLog
          modalDetailedLogOpen={modalDetailedLogOpen}
          modalDetailedLogClose={modalDetailedLogClose}
          modaldetailedlog={modaldetailedlog}
          setModalDetailedLog={setModalDetailedLog}
          userIdDialog={userIdDialog}
        />
      )}
      {nttImageDialog && (
        <NTTImageDialog
          open={nttImageDialog}
          onClose={() => {
            nttImageDialogClose();
          }}
          activity={selectedActivity}
        />
      )}
    </>
  );
};

export default UserActivityList;
