import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import moment from "moment";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { TimePicker } from "@mui/lab";
import { updateShiftSchedule } from "../../../api";
import {
  Button as MuiButton,
  FormControl as MuiFormControl,
  IconButton,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
import Toaster from "../../tasks/Toaster";

const Button = styled(MuiButton)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);

const FormControl = styled(FormControlSpacing)`
  width: 100%;
`;

const ShiftSchedule = ({
  shiftoneopen,
  shiftOneClose,
  tovalue,
  setToValue,
  fromvalue,
  setFromValue,
  shiftName,
  setValidateTime,
}) => {
  const { id } = useParams();

  const handleSubmit = async () => {
    try {
      const formattedFrom = moment(fromvalue).format("HH:mm");
      const formattedTo = moment(tovalue).format("HH:mm");

      const shiftData = {
        shift: shiftName,
        from_time: formattedFrom,
        to_time: formattedTo,
      };

      const response = await updateShiftSchedule(id, shiftData);
      console.log("shiftdata", shiftData);
      console.log("response shiftdata ", response);

      if (response.statusCode === 200) {
        console.log("Shift updated successfully");
        shiftOneClose();
      } else {
        console.log("Error updating shift:", response);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    setToValue(null);
    setFromValue(null);
  }, [shiftName]);

  return (
    <>
      <Dialog
        open={shiftoneopen}
        onClose={shiftOneClose}
        aria-labelledby="shift-modal-title"
      >
        <DialogTitle
          id="shift-modal-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1.25rem",
          }}
        >
          <Typography variant="h5" component="div">
            Set alert for failed mobile logins
          </Typography>
          <IconButton onClick={shiftOneClose}>
            <CancelIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 3, fontSize: "0.875rem" }}>
            Using the fields below, set a time range of 15 minutes when you
            expect a login to occur. If SOVA does not detect any mobile logins
            during this time, an email and/or SMS alert will be sent to
            recipients in the communication list "No Login". This can be useful
            in receiving timely alerts if officers fail to report for duty on
            time.
          </DialogContentText>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TimePicker
                  label="Time From"
                  value={fromvalue}
                  onChange={(newValue) => {
                    setFromValue(newValue);
                    setValidateTime(
                      newValue && tovalue ? newValue < tovalue : true
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="Time To"
                  value={tovalue}
                  onChange={(newValue) => {
                    setToValue(newValue);
                    setValidateTime(
                      fromvalue && newValue ? fromvalue < newValue : true
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="error" variant="body2">
                  {fromvalue && tovalue && fromvalue >= tovalue
                    ? "Time From must be before Time To"
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            <DialogActions
              sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                onClick={shiftOneClose}
                color="secondary"
                variant="outlined"
              >
                Close
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained"
                sx={{ ml: 2 }}
              >
                Save
              </Button>
            </DialogActions>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShiftSchedule;
