import React from "react";
import styled from "styled-components/macro";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
  Grid,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel CSS

const Image = styled.img`
  width: 600px;
  height: 400px;
  object-fit: contain; /* Ensure the image is contained */
`;

const NTTImageDialog = ({ open, onClose, activity }) => {
  // Convert activity.images to an array of objects for the carousel
  const images = activity?.images || [];

  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Box-shadow for the dialog box
              borderRadius: "12px", // Rounded corners for the dialog
            },
          },
        }}
        open={open}
        onClose={onClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
            fontSize: "1.2rem", // Slightly larger font for the title
          }}
        >
          <Typography variant="h3">
            {activity?.buttonName} - {activity?.task_location}
          </Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton sx={{ marginRight: "-10px" }} onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} mb={0} sx={{ textAlign: "center" }}>
              {/* Center the carousel with padding */}
              <Box
                sx={{
                  marginTop: "30px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                }}
              >
                {/* Render the carousel if images exist */}
                {images.length > 0 && (
                  <Carousel
                    infiniteLoop
                    showThumbs={true}
                    autoPlay={true}
                    interval={5000}
                    stopOnHover={true}
                    swipeable={true}
                  >
                    {images.map((image, index) => (
                      <div key={index}>
                        <Image src={image} alt={`Image ${index + 1}`} />
                      </div>
                    ))}
                  </Carousel>
                )}
              </Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "1rem", // Bold and slightly larger font for the label
                  }}
                >
                  Comment:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1rem", // Regular font for the comment
                    fontStyle: "italic", // Optionally, you can add italics to make it more distinctive
                  }}
                >
                  {activity?.comments ? activity?.comments : "No comment added"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NTTImageDialog;
