import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { StaffNotification } from "../../../api";
import { useDispatch } from "react-redux";
import {
  getMsg,
  getSnackAlert,
  getSnackOpen,
  getSnackClose,
} from "../../../redux/slices/mainSlice";

const BulkSmsDialog = ({ open, onClose, staffData }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false); // Loading state for button

  const handleSendNotification = async () => {
    setIsSending(true); // Start loading
    try {
      const res = await StaffNotification({ notification: message });
      dispatch(getMsg("Notification sent successfully!"));
      dispatch(getSnackAlert("success"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      console.log("Staff Notification Data: ", res);
    } catch (error) {
      dispatch(getMsg("Something went wrong!"));
      dispatch(getSnackAlert("error"));
      dispatch(getSnackOpen());
      setTimeout(() => {
        dispatch(getSnackClose());
      }, 3000);
      console.log("Error while sending staff notification: ", await error);
    } finally {
      setIsSending(false); // Stop loading
      onClose(); // Close dialog after response
    }
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
            borderRadius: "8px",
          },
        },
      }}
      open={open}
      onClose={onClose}
    >
      {/* Dialog Title */}
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Staff SMS Notification
        </Typography>
        <IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
        {/* SMS Input Field */}
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          placeholder="Add text message here. 160 characters max."
          inputProps={{ maxLength: 160 }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />

        {/* Recipients Count Display */}
        <Typography
          variant="body2"
          sx={{
            // textAlign: "center",
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          Text message will be sent to{" "}
          {staffData?.total_rec ? staffData?.total_rec : 0} recipient
          {staffData?.total_rec > 1 ? "s" : ""}
        </Typography>
      </DialogContent>

      {/* Dialog Actions with Space Between */}
      <DialogActions
        sx={{ justifyContent: "flex-end", gap: "20px", padding: "15px" }}
      >
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          disabled={isSending || message.trim() === ""}
          onClick={handleSendNotification}
        >
          {isSending ? (
            <>
              <CircularProgress
                size={16}
                sx={{ color: "white", marginRight: "8px" }}
              />
              Sending...
            </>
          ) : (
            "Send"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkSmsDialog;
