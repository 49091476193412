import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Box,
  TextField,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  DatePicker,
  TimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const TaskTimeIssueDialog = ({ open, onClose, onConfirm }) => {
  const [adjustedDate, setAdjustedDate] = useState(moment());
  const [adjustedTime, setAdjustedTime] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = async (useAdjustedTime) => {
    setIsSubmitting(true);
    try {
      if (!useAdjustedTime) {
        await onConfirm(null); // If user chooses to leave it as-is, return null
      } else {
        // Create a new JavaScript Date object
        const combinedDateTime = new Date(
          adjustedDate.year(),
          adjustedDate.month(),
          adjustedDate.date(),
          adjustedTime.hour(),
          adjustedTime.minute(),
          0 // Seconds set to 0
        );

        await onConfirm(combinedDateTime); // Pass the JavaScript Date object
      }
    } catch (error) {
      console.error("Error updating task time:", error);
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
            borderRadius: "8px",
          },
        },
      }}
    >
      {/* Dialog Header */}
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Task Time Issue
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent sx={{ paddingBottom: 2 }}>
        <Typography variant="body2" sx={{ mb: 5 }}>
          This task was started less than 1 minute ago. If this is not accurate,
          please insert the correct start time for the task in the box below.
        </Typography>

        <LocalizationProvider dateAdapter={AdapterMoment}>
          {/* Adjusted Date Picker */}
          <DatePicker
            label="Adjusted Date"
            value={adjustedDate}
            onChange={(newDate) => setAdjustedDate(newDate)}
            renderInput={(params) => (
              <Box sx={{ mb: 2 }}>
                <TextField fullWidth {...params} />
              </Box>
            )}
          />

          {/* Adjusted Time Picker */}
          <TimePicker
            label="Adjusted Start Time"
            value={adjustedTime}
            onChange={(newTime) => setAdjustedTime(newTime)}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </LocalizationProvider>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions sx={{ justifyContent: "center", pb: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            onConfirm("");
          }}
        >
          Leave start time as-is
        </Button>

        <Button
          variant="contained"
          disabled={!adjustedTime || isSubmitting}
          onClick={handleConfirm}
        >
          {isSubmitting ? (
            <CircularProgress size={16} sx={{ color: "white", mr: 1 }} />
          ) : (
            "Use adjusted start time"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskTimeIssueDialog;
