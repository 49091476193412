import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  TableRow,
  Dialog,
  MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Stack,
  Typography,
  Grid,
  Card,
  Paper,
  Box as MuiBox,
  CardContent,
  Chip as MuiChip,
  Avatar as MuiAvatar,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { green, orange, red } from "@mui/material/colors";
import { CancelRounded as CancelRoundedIcon } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
import { RecentUserActivity, getUserDetails } from "../../../api";
import PropTypes from "prop-types";
import { spacing } from "@mui/system";
import { set } from "date-fns";
import moment from "moment";
import Infocards from "../../dashboards/Task/Infocards";
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const Avatar = styled(MuiAvatar)`
  // display: inline-block;
  height: 100px;
  width: 100px;
`;
const ImageWrapper = styled.div``;
const Image = styled.img`
  width: 100%;
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    font-size: 14px;
    font-weight: 600;
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#17212f" : "rgb(229,246,253)"};

    color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#fff" : "#646464"};
    position: sticky;
    top: 0;
    padding: 14px 15px;
  }
  &.${tableCellClasses.body} {
    font-size: 12px;
    font-weight: 500;
    padding: 8px 15px;
    color: ${(props) => props.theme.palette.white};
    background-color: ${(props) =>
      props.theme.palette.mode === "dark" ? "#00000045" : "#fafafa"};
  }
`;
const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.completed && green[500]};
  background: ${(props) => props.processing && orange[700]};
  color: ${(props) => props.theme.palette.common.white};
`;
const Box = styled(MuiBox)`
  ${spacing};
  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#00000045" : "#fafafa"};
  // padding: 15px;
  border-radius: 10px;
`;

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};
const DetailedLog = ({
  modaldetailedlog,
  modalDetailedLogClose,
  userIdDialog,
  calender = false,
}) => {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState("lg");
  const [data, setData] = useState({});

  const {
    User,
    UserActivity,
    Incident,
    TourRunStats,
    TotalHoursByUser,
    location_name_data,
    highChart,
  } = data;

  // states for loader
  const [pageLoad, setPageLoad] = useState(false);
  const classes = useStyles();

  const perc2color = (perc) => {
    var r,
      g,
      b = 0;
    if (perc > 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
  };

  const getRecentUserActivity = async () => {
    setPageLoad(true);

    try {
      if (calender) {
        const id = userIdDialog;
        const response = await getUserDetails(id);
        setData(response?.data);
      } else {
        const payload = {
          userId: userIdDialog,
        };

        const res = await RecentUserActivity(payload);

        if (res.statusCode === 200) {
          setData(res?.data);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setPageLoad(false);
    }
  };

  useEffect(() => {
    getRecentUserActivity();
  }, []);

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="activity-title"
        aria-describedby="activity-description"
        open={modaldetailedlog}
        onClose={modalDetailedLogClose}
      >
        {pageLoad ? (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              id="activity-title"
            >
              <Typography variant="h4">{`Recent User Activity`}</Typography>
              <DialogActions>
                <IconButton
                  onClick={modalDetailedLogClose}
                  sx={{ marginRight: "-10px" }}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </DialogActions>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <CircularProgress />
              </Box>
            </DialogContent>
          </>
        ) : Object.keys(data).length > 0 ? (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              id="activity-title"
            >
              <Typography variant="h4">
                {`Recent User Activity: ${User?.UserName}`}
              </Typography>
              <DialogActions>
                <IconButton
                  onClick={modalDetailedLogClose}
                  sx={{ marginRight: "-10px" }}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </DialogActions>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={6}>
                <Grid item xs={12} md={5} lg={5}>
                  <Box
                    sx={{
                      margin: "18px",
                      padding: "10px",
                      borderRadius: "12px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      backgroundColor: "white",
                    }}
                  >
                    <Grid container spacing={6} alignItems="center">
                      <Grid item xs={4} md={3}>
                        <Avatar alt="Lucy Lavender" src={User?.profile_image} />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography
                          component="p"
                          sx={{ fontSize: "16px", marginLeft: "18px" }}
                        >
                          {`Phone: ${User?.Phone ? User?.Phone : "n/a"}`}
                        </Typography>
                        <Typography
                          component="p"
                          sx={{ fontSize: "16px", marginLeft: "18px" }}
                        >
                          {`Email: ${User?.Email ? User?.Email : "n/a"}`}
                        </Typography>
                      </Grid>
                      {UserActivity?.IsLoggedIn && (
                        <Grid xs={12} align="right">
                          <Button
                            variant="outlined"
                            mt={5}
                            sx={{
                              borderRadius: "30px",
                              padding: "8px 15px",
                              minWidth: "120px",
                              marginLeft: "auto",
                              display: "block",
                            }}
                          >
                            Logout
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                  <Grid
                    container
                    spacing={6}
                    sx={{
                      "& .MuiTypography-root": {
                        display: "block",
                        textAlign: "center",
                        marginBottom: "20px",
                        color: "black",
                        textDecoration: "none",
                      },
                      "& a": {
                        textDecoration: "none",
                      },
                      "& .dataTitle": {
                        marginBottom: 0,
                        fontSize: "17px",
                        fontWeight: "600",
                      },
                      "& .guage-text-left": {
                        width: "80px",
                        textAlign: "center",
                        marginLeft: "-20px",
                      },
                      "& .guage-text-right": {
                        width: "80px",
                        textAlign: "center",
                        marginRight: "-20px",
                      },
                    }}
                  >
                    <Grid item xs={12} sm={3} xl={3}>
                      <Infocards
                        sx={{
                          background: "#fafafa",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                        title={
                          <Typography variant="body2">
                            Tours completed today
                          </Typography>
                        }
                        linkTo="/reports/tour-patrol"
                        percentagecolor={perc2color(
                          TotalHoursByUser
                            ? (TotalHoursByUser?.completedTour / 20) * 100
                            : 0
                        )}
                        revScore={
                          <Paper
                            sx={{
                              background: "#fafafa",
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle">
                              {TotalHoursByUser?.completedTour}
                            </Typography>
                            <Typography>Tours</Typography>
                            <div
                              className="guage-numbers"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography className="guage-text-left">
                                0
                              </Typography>
                              <Typography className="guage-text-right">
                                20
                              </Typography>
                            </div>
                          </Paper>
                        }
                        progressPercent={
                          (TotalHoursByUser?.completedTour / 20) * 100
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Infocards
                        sx={{
                          background: "#fafafa",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                        title={
                          <Typography variant="body2">
                            Monthly Task Count
                          </Typography>
                        }
                        linkTo="/reports/tour-patrol"
                        percentagecolor={perc2color(
                          TotalHoursByUser
                            ? (TotalHoursByUser?.countincedent / 250) * 100
                            : 0
                        )}
                        revScore={
                          <Paper
                            sx={{
                              background: "#fafafa",
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle">
                              {TotalHoursByUser?.countincedent}
                            </Typography>
                            <Typography>Tasks</Typography>
                            <div
                              className="guage-numbers"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography className="guage-text-left">
                                0
                              </Typography>
                              <Typography className="guage-text-right">
                                250
                              </Typography>
                            </div>
                          </Paper>
                        }
                        progressPercent={
                          (TotalHoursByUser?.countincedent / 250) * 100
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Infocards
                        sx={{
                          background: "#fafafa",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                        title={
                          <Typography variant="body2">
                            Monthly Tour Hours
                          </Typography>
                        }
                        linkTo="/reports/tour-patrol"
                        percentagecolor={perc2color(
                          TotalHoursByUser
                            ? (TotalHoursByUser?.total_hours_worked / 20) * 100
                            : 0
                        )}
                        revScore={
                          <Paper
                            sx={{
                              background: "#fafafa",
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle">
                              {TotalHoursByUser?.total_hours_worked}
                            </Typography>
                            <Typography>Hours</Typography>
                            <div
                              className="guage-numbers"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography className="guage-text-left">
                                0
                              </Typography>
                              <Typography className="guage-text-right">
                                20
                              </Typography>
                            </div>
                          </Paper>
                        }
                        progressPercent={
                          (TotalHoursByUser?.total_hours_worked / 20) * 100
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} xl={3}>
                      <Infocards
                        sx={{
                          background: "#fafafa",
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                        title={
                          <Typography variant="body2">
                            Monthly Task Hours
                          </Typography>
                        }
                        linkTo="/reports/tour-patrol"
                        percentagecolor={perc2color(
                          TotalHoursByUser
                            ? (TotalHoursByUser?.non_tour_time_taken / 250) *
                                100
                            : 0
                        )}
                        revScore={
                          <Paper
                            sx={{
                              background: "#fafafa",
                              "& .guage-numbers": {
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                width: "100%",
                              },
                            }}
                          >
                            <Typography className="dataTitle">
                              {TotalHoursByUser?.non_tour_time_taken}
                            </Typography>
                            <Typography>Hours</Typography>
                            <div
                              className="guage-numbers"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography className="guage-text-left">
                                0
                              </Typography>
                              <Typography className="guage-text-right">
                                250
                              </Typography>
                            </div>
                          </Paper>
                        }
                        progressPercent={
                          (TotalHoursByUser?.non_tour_time_taken / 250) * 100
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Paper>
                    <Typography
                      variant="h4"
                      component="div"
                      color="primary"
                      gutterBottom
                    >
                      {` Activity Log Today's date: ${moment().format(
                        "MM-DD-YYYY"
                      )}`}
                    </Typography>
                    <Stack mt={3}>
                      <TableContainer
                        className="tableContainer"
                        sx={{
                          maxHeight: "350px",
                          "&::-webkit-scrollbar": {
                            width: 5,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#F7F9FC",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#f3f3f3",
                            borderRadius: 2,
                          },
                        }}
                      >
                        <Table
                          sx={{ height: "max-content" }}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <CustomTableCell component="th">
                                Activity Time
                              </CustomTableCell>
                              <CustomTableCell component="th">
                                Activity Type
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {UserActivity?.map((activity) => {
                              return (
                                <TableRow>
                                  <CustomTableCell component="td" scope="row">
                                    {activity.activity_time}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {`${activity.activity_type}-${activity.display_name}`}
                                  </CustomTableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Paper>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <Paper>
                    <Typography variant="h4" color="primary">
                      Incident Reports
                    </Typography>
                    <Stack mt={3}>
                      <TableContainer
                        className="tableContainer"
                        sx={{
                          maxHeight: "350px",
                          "&::-webkit-scrollbar": {
                            width: 5,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#F7F9FC",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#f3f3f3",
                            borderRadius: 2,
                          },
                        }}
                      >
                        <Table
                          sx={{ height: "max-content" }}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <CustomTableCell component="th">
                                Incident Type
                              </CustomTableCell>
                              <CustomTableCell component="th">
                                Incident Date
                              </CustomTableCell>
                              <CustomTableCell component="th">
                                Incident Time
                              </CustomTableCell>
                              <CustomTableCell component="th">
                                Incident Status
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Incident?.map((incident) => {
                              return (
                                <TableRow>
                                  <CustomTableCell component="td" scope="row">
                                    {incident.IncidentType.IncidentTypeName}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {moment(incident.start_time).format(
                                      "MM-DD-YYYY"
                                    )}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {moment(incident.start_time).format(
                                      "HH:mm:ss"
                                    )}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {incident.status}
                                  </CustomTableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Paper>
                  <Paper mt={6}>
                    <Typography variant="h4" color="primary" mt={6}>
                      Tour Activity
                    </Typography>
                    <Stack mt={3}>
                      <TableContainer
                        className="tableContainer"
                        sx={{
                          maxHeight: "350px",
                          "&::-webkit-scrollbar": {
                            width: 5,
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#F7F9FC",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#f3f3f3",
                            borderRadius: 2,
                          },
                        }}
                      >
                        <Table
                          sx={{ height: "max-content" }}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <CustomTableCell component="th">
                                Tour Name
                              </CustomTableCell>
                              <CustomTableCell component="th">
                                Start Time
                              </CustomTableCell>
                              <CustomTableCell component="th">
                                Result
                              </CustomTableCell>
                              <CustomTableCell component="th">
                                End Time
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {TourRunStats?.map((tour) => {
                              return (
                                <TableRow>
                                  <CustomTableCell component="td" scope="row">
                                    {tour.TourName}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {tour.StartTime}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    <Chip
                                      size="small"
                                      label={`${tour.Scanned}/${tour.Total}`}
                                      color={
                                        (tour.Scanned / tour.Total) * 100 >= 60
                                          ? (tour.Scanned / tour.Total) * 100 >=
                                            80
                                            ? "success"
                                            : "warning"
                                          : "error"
                                      }
                                    />
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {tour.FinishTime}
                                  </CustomTableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              id="activity-title"
            >
              <Typography variant="h4">{`Recent User Activity`}</Typography>
              <DialogActions>
                <IconButton
                  onClick={modalDetailedLogClose}
                  sx={{ marginRight: "-10px" }}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </DialogActions>
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "300px",
                }}
              >
                <Typography variant="h3">No Records Found!!</Typography>
              </Box>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default DetailedLog;
