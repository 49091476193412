import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { StaffNotification } from "../../../api";
import { useDispatch } from "react-redux";

const DeviceNameDialog = ({ open, onClose, deviceNames = [] }) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
            borderRadius: "8px",
          },
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "0px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Device Name(s)
        </Typography>
        <IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
          <CancelIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Typography>
          {deviceNames?.length > 0
            ? deviceNames.join(", ")
            : "No device(s) found"}{" "}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{ justifyContent: "flex-end", gap: "20px", padding: "15px" }}
      >
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceNameDialog;
