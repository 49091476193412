import React from "react";
import styled from "styled-components/macro";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  Button,
  IconButton,
  Box,
  Grid,
} from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";
import moment from "moment";
const Image = styled.img`
  width: "250px";
  hright: "400px";
`;

const LiveCastPicsModal = ({
  castPicsModal,
  setCastPicsModal,
  castPicsModalOpen,
  castPicsModalClose,
  selectedPic,
}) => {
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "700px",
            },
          },
        }}
        open={castPicsModal}
        onClose={castPicsModalClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h3">Image #{selectedPic?.id}</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setCastPicsModal(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={6} mb={10}>
              <Box
                sx={{
                  marginTop: "30px",
                  marginLeft: "15px",
                }}
              >
                <Image src={selectedPic?.CastFile} />
              </Box>
            </Grid>
            <Grid item xs={6} mb={10}>
              <Typography variant="h6" gutterBottom mt={7}>
                Cast Pic Detail:
              </Typography>
              <Typography variant="body2" gutterBottom>
                Message: {selectedPic?.CastMessage}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Date Time:{" "}
                {moment(selectedPic?.RecordTime).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LiveCastPicsModal;
