import {
  Grid,
  Box,
  Button,
  Tooltip,
  Typography,
  MenuItem,
  Menu,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Reply as ReplyIcon } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState, useEffect } from "react";
import Map from "./inner-components/Map";
import { BacktrackIncidentsAjax } from "../../api";

import { OverlayView, InfoWindow } from "@react-google-maps/api";
import styled from "styled-components/macro";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    maxWidth: "140px",
    width: "100%",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiMenuItem-root": {
        display: "block",
        "& .MuiList-root": {
          paddingTop: "0px",
          marginTop: "15px",
          paddingBottom: "0px",
          "& .MuiListItem-root": {
            padding: "0px",
            background: "#7aa8ff0d",
            "& .MuiListItemButton-root": {
              padding: "3px 5px",
            },
          },
        },
      },

      "& .Mui-focusVisible": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const BacktrackIncidents = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [siteLocation, setSiteLocation] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 37.784279,
    lng: -122.407234,
  });
  const [filterOptions, setFilterOptions] = useState({
    start_date: moment().subtract(28, "days").format("MM/DD/YYYY"),
    end_date: moment().format("MM/DD/YYYY"),
  });
  const [backtrackData, setBacktrackData] = useState({
    map_data: [],
    map_dir: 0,
  });
  const [uniqueItems, setUniqueItems] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [expandedMarker, setExpandedMarker] = useState(null);

  // Handle changes in filter options
  const handleFilterChange = (key, value) => {
    setFilterOptions((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSearch = () => {
    getBacktrackData();
  };

  //   const getSpiralPositions = (
  //     center,
  //     count,
  //     baseSpacing = 10,
  //     angleIncrement = 0.5
  //   ) => {
  //     const positions = [];
  //     let radius = baseSpacing; // Initial radius
  //     let angle = 0; // Start angle

  //     for (let i = 0; i < count; i++) {
  //       // Calculate latitude and longitude offsets using polar coordinates
  //       const latOffset = (radius / 111000) * Math.cos(angle); // Latitude adjustment
  //       const lngOffset =
  //         (radius / (111000 * Math.cos((center.lat * Math.PI) / 180))) *
  //         Math.sin(angle); // Longitude adjustment

  //       positions.push({
  //         lat: center.lat + latOffset,
  //         lng: center.lng + lngOffset,
  //       });

  //       // Increment radius and angle for next marker
  //       angle += angleIncrement; // Progress spiral
  //       radius += baseSpacing / (2 * Math.PI); // Increase radius for outward growth
  //     }

  //     return positions;
  //   };

  const getBacktrackData = async () => {
    try {
      // Construct the "date-range" string from filterOptions
      setLoading(true);
      const dateRangeString = `${filterOptions.start_date} - ${filterOptions.end_date}`;

      // Build the payload in the required format
      const payload = {
        "date-range": dateRangeString,
      };

      // Pass that payload to your AJAX call
      const res = await BacktrackIncidentsAjax(payload);
      setBacktrackData(res.data);
    } catch (error) {
      console.log("Error while fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const CustomMarker = ({ location, onClick }) => {
    const setMarkerPosition = () => {
      if (
        location &&
        !isNaN(parseFloat(location?.GPSLat)) && // Safely parse latitude
        !isNaN(parseFloat(location?.GPSLong)) // Safely parse longitude
      ) {
        return {
          lat: parseFloat(location?.GPSLat), // Convert latitude string to number
          lng: parseFloat(location?.GPSLong), // Convert longitude string to number
        };
      } else {
        return siteLocation ? siteLocation : { lat: 0, lng: 0 }; // Fallback to siteLocation if invalid
      }
    };

    return (
      <OverlayView
        position={setMarkerPosition()}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <>
          <div
            style={{
              position: "relative",
              width: "25px", // Overall size of the marker
              height: "25px",
              cursor: "pointer",
            }}
            onClick={onClick}
          >
            {/* Donut Marker */}
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                borderRadius: "50%", // Make it a circle
                background: `radial-gradient(circle, transparent 30%, ${
                  location.color_code || "#000000"
                } 25%)`, // Smaller inner circle with dynamic color
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              title={`Classification: ${location?.IncidentType}`}
            ></div>
          </div>
        </>
      </OverlayView>
    );
  };

  //   const CustomMarker = ({ locations, centerLocation }) => {
  //     const markerId = `${centerLocation.lat}-${centerLocation.lng}`;
  //     const expanded = expandedMarker === markerId;

  //     const handleClick = () => {
  //       setExpandedMarker(expanded ? null : markerId); // Toggle expanded state
  //     };

  //     return (
  //       <>
  //         {expanded ? (
  //           // Display markers in a circular layout when expanded
  //           getSpiralPositions(centerLocation, locations.length, 10).map(
  //             (pos, index) => (
  //               <OverlayView
  //                 key={index}
  //                 position={pos}
  //                 mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //               >
  //                 <div
  //                   style={{
  //                     position: "relative",
  //                     width: "25px",
  //                     height: "25px",
  //                     cursor: "pointer",
  //                     borderRadius: "50%",
  //                     border: `8px solid ${
  //                       locations[index]?.color_code || "#000"
  //                     }`, // Solid ring color
  //                     backgroundColor: "transparent", // Transparent center
  //                   }}
  //                   title={`User: ${locations[index]?.user_name}`}
  //                 />
  //               </OverlayView>
  //             )
  //           )
  //         ) : (
  //           // Display a single grouped marker
  //           <OverlayView
  //             position={centerLocation}
  //             mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  //           >
  //             <div
  //               style={{
  //                 position: "relative",
  //                 width: "30px",
  //                 height: "30px",
  //                 borderRadius: "50%",
  //                 cursor: "pointer",
  //                 border: `10px solid ${locations[0]?.color_code || "#FF0000"}`, // Solid ring
  //                 backgroundColor: "transparent", // Transparent center
  //               }}
  //               onClick={handleClick}
  //             />
  //           </OverlayView>
  //         )}
  //       </>
  //     );
  //   };

  const CustomInfoWindow = ({ location, onClose }) => {
    const setWidnowPostion = () => {
      if (
        location &&
        !isNaN(parseFloat(location?.GPSLat)) && // Safely parse latitute
        !isNaN(parseFloat(location?.GPSLong)) // Safely parse longitude
      ) {
        return {
          lat: parseFloat(location?.GPSLat), // Convert latitute string to number
          lng: parseFloat(location?.GPSLong), // Convert longitude string to number
        };
      } else {
        return siteLocation ? siteLocation : { lat: 0, lng: 0 }; // Fallback to siteLocation if invalid
      }
    };
    return (
      <InfoWindow
        position={setWidnowPostion()}
        onCloseClick={() => {
          onClose();
        }}
      >
        <div
          style={{
            minWidth: "320px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Info Content */}
          <div style={{ paddingRight: "12px" }}>
            <p>
              <strong>IR Number:</strong> {location?.IncidentReportName}
            </p>
            <p>
              <strong>Classification:</strong> {location?.IncidentType}
            </p>
            <p>
              <strong>Location:</strong> {location?.IncidentLocation}
            </p>
            <p>
              <strong>Date:</strong> {location?.IncidentTimeReported}
            </p>
          </div>
        </div>
      </InfoWindow>
    );
  };

  const toggleVisibility = (key) => {
    // Toggle `show` for the specific user on click
    setUniqueItems((prevItems) =>
      prevItems.map((item) =>
        item.IncidentTypeName === key
          ? { ...item, isVisible: !item.isVisible } // Toggle the `show` key
          : item
      )
    );
  };

  useEffect(() => {
    getBacktrackData();
  }, []);

  useEffect(() => {
    if (backtrackData.map_data) {
      const uniqueData = Object.values(
        backtrackData.map_data.reduce((acc, item) => {
          acc[item.IncidentTypeName] = acc[item.IncidentTypeName] || {
            ...item,
            isVisible: true,
          }; // Store first occurrence
          return acc;
        }, {})
      );

      setUniqueItems(uniqueData);
    }
  }, [backtrackData.map_data]);

  useEffect(() => {
    if (backtrackData?.map_data) {
      const visibleItems = uniqueItems
        .filter((item) => item.isVisible)
        .map((item) => item.IncidentTypeName);

      const newFilteredLocations = backtrackData.map_data.filter((location) =>
        visibleItems.includes(location.IncidentTypeName)
      );

      setFilteredLocations(newFilteredLocations);
    }
  }, [uniqueItems, backtrackData]);

  return (
    <>
      <Box sx={{ height: "85vh", position: "relative" }}>
        <Grid container sx={{ height: "100%" }}>
          {/* Overlay Loader */}
          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
          <Grid item xs={12} sx={{ height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {/* Header */}
              <Box
                sx={{
                  backgroundColor: "white",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  padding: 2,
                  borderRadius: 1,
                  flexShrink: 0,
                  height: "15%", // Fixed height for header
                }}
              >
                <Typography variant="h3" gutterBottom>
                  Backtrack Incidents
                </Typography>
                <Grid container spacing={2} alignItems="center">
                  {/* Start Date Picker */}
                  <Grid item xs={12} sm={3} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Start Date"
                        value={moment(filterOptions.start_date, "MM/DD/YYYY")}
                        onChange={(newValue) =>
                          handleFilterChange(
                            "start_date",
                            newValue.format("MM/DD/YYYY")
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ minWidth: "150px" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* End Date Picker */}
                  <Grid item xs={12} sm={3} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="End Date"
                        value={moment(filterOptions.end_date, "MM/DD/YYYY")}
                        onChange={(newValue) =>
                          handleFilterChange(
                            "end_date",
                            newValue.format("MM/DD/YYYY")
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{ minWidth: "150px" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {/* Search Button */}
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>

                  {/* Action Buttons */}
                  <Grid item xs={12} sm={3} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: 5,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: "8px" }}
                        onClick={() => navigate("../backtrack")}
                      >
                        <ReplyIcon
                          sx={{ fontSize: "20px", marginRight: "4px" }}
                        />
                        Backtrack
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ borderRadius: "8px" }}
                        onClick={() => {
                          navigate("../backtrack-tasks");
                        }}
                      >
                        Tasks
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Map */}
              <Box
                sx={{
                  flexGrow: 1,
                  borderRadius: 1,
                  overflow: "hidden",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fff",
                  position: "relative",
                }}
              >
                <Map
                  locations={filteredLocations}
                  CustomMarker={CustomMarker}
                  CustomInfoWindow={CustomInfoWindow}
                  mapCenter={mapCenter}
                  zoomLevel={2}
                />
              </Box>
              {/* UserList Section */}
              {!loading && uniqueItems.length > 0 && (
                <Box
                  sx={{
                    flex: 0.1,
                    transition: "flex 0.3s ease",
                    overflowY: "auto",
                    // marginTop: 1,
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {/* List Section */}
                  <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                    {uniqueItems.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          cursor: "pointer",
                          opacity: item.isVisible ? 1 : 0.5,
                        }}
                        onClick={() => toggleVisibility(item.IncidentTypeName)}
                      >
                        <Box
                          sx={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: item.color_code,
                            borderRadius: "50%",
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ fontSize: "12px", cursor: "pointer" }}
                        >
                          {item.IncidentTypeName}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BacktrackIncidents;
