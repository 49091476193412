import React from "react";
import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const TextfieldWrapper = ({ name, type, ...otherProps }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  // If type is 'time', render TimePicker
  if (type === "time") {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label={otherProps.label}
          value={field.value || null}
          onChange={(newValue) => {
            setFieldValue(name, newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...configTextfield}
              onClick={(e) => {
                e.preventDefault(); // Prevents default focus behavior
              }}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  return <TextField {...configTextfield} />;
};

export default TextfieldWrapper;
